<template>
    <div class="self-extract-container" style="background-color: #ffffff">
        <div class="header">
            <div>
                <search-v2
                        class="doneOrderList_searchBox_item header-search"
                        v-model="keyword"
                        @search="search"
                        @clear="search"
                        placeholder="请输入商品名称"

                ></search-v2>
                <div class="date-ranger-container" style="padding-top: 10px;">
                    <date-ranger-v1 :orginDate="date" @chooseDate="chooseDate"></date-ranger-v1>
                </div>
            </div>

        </div>


        <!--供货商统计-->
        <div v-if="authList.indexOf(3)>-1">
            <div class="div-middle"></div>
            <div class="tongji-body" style="margin-bottom:8px;">

                <!--商品名称-->
                <div class="tongji-head">
                    <div style="display:flex;justify-content: left;align-items: center;">
                        <div class="tongji-head-tip"></div>
                        <div>商家名称</div>
                    </div>

                    <search-v2
                            class="doneOrderList_searchBox_item header-search"
                            v-model="keywordTwo"
                            @search="search"
                            @clear="search"
                            placeholder="请输入供货商名称"
                    ></search-v2>

                </div>


                <!--统计结果-->
                <div class="tongji-result" v-if="storeList.length==0">
                    <div class="tongji-result-title">暂无数据</div>
                </div>
                <div class="tongji-result" v-for="(item, index) in storeList" :key="index">
                    <div class="tongji-result-title">{{item.storeName}}</div>
                    <div class="tongji-result-line"></div>
                    <div class="tongji-result-desc" @click="showStoresList(index,'stores')">
                        商品总数：{{item.count}}件
                        <img v-if="show && index==storeIndex && showTip=='stores'"  class="tongji-result-desc-tip" :src="require('@/static/image/icons/r-down.png')">
                        <img v-else class="tongji-result-desc-tip" :src="require('@/static/image/icons/r-up.png')">
                    </div>


                    <div  v-show="show && index==storeIndex && showTip=='stores'" class="tongji-result-details"  v-for="(dItem,dIndex) in item.list" :key="dIndex">
                        <div style="width:260px;">
                            {{dIndex+1}}.{{dItem.title}}
                        </div>
                        <div class="tongji-result-details-tip">{{dItem.goods_total}}件</div>
                    </div>

                </div>

            </div>
        </div>



        <!--自提点配送统计-->
        <div v-if="authList.indexOf(3)>-1">
            <div class="div-middle"></div>
            <div class="tongji-body">
                <div class="tongji-head">
                    <div style="display:flex;justify-content: left;align-items: center;">
                        <div class="tongji-head-tip"></div>
                        <div>自提点名称</div>
                    </div>

                    <div>
                        <search-v2
                                class="doneOrderList_searchBox_item header-search"
                                v-model="keywordThree"
                                @search="search"
                                @clear="search"
                                placeholder="请输入自提点名称"

                        ></search-v2>
                    </div>
                    <div @click="orderShow=!orderShow">筛选</div>
                </div>

                <!--统计结果-->
                <div class="tongji-result" style="background: rgba(23, 127, 244, 0.1);" v-if="pointList.length==0">
                    <div class="tongji-result-title">暂无数据</div>
                </div>
                <div class="tongji-result" style="background: rgba(23, 127, 244, 0.1);"  v-for="(item, index) in pointList" :key="index">
                    <div class="tongji-result-title">{{item.site_name}}</div>
                    <div class="tongji-result-line" style="background: rgba(23, 127, 244, 0.1);"></div>
                    <div class="tongji-result-desc" style="color: #177FF4;" @click="showStoresList(index,'sites')">
                        商品总数：{{item.count}}件
                        <img  v-if="show && index==storeIndex && showTip=='sites'" class="tongji-result-desc-tip" :src="require('@/static/image/icons/g-down.png')">
                        <img v-else class="tongji-result-desc-tip" :src="require('@/static/image/icons/g-up.png')">

                    </div>


                    <div  v-show="show && index==storeIndex && showTip=='sites'" class="tongji-result-details"  v-for="(dItem,dIndex) in item.list" :key="dIndex">
                        <div style="width:260px;">
                            {{dIndex+1}}.{{dItem.title}}
                        </div>
                        <div class="tongji-result-details-tip">{{dItem.goods_total}}件</div>
                    </div>
                    <div  v-show="show && index==storeIndex && showTip=='sites'" class="tongji-result-details-money">
                        <div>总金额：￥{{item.sumMoney}}</div>
                        <div>总利润：￥{{item.sumProfit}}</div>

                    </div>

                </div>

            </div>
        </div>

        <div v-if="orderShow" class="sort_list">
            <div class="sort_list_select">
                <div v-for="(pItem,pIndex) in options" :key="pIndex">
                    <div class="sort_list_text" @click="checkOrder(pIndex,pItem.id)">
                        <div style="margin-left:20px;">{{pItem.name}}</div>
                        <div style="margin-right:20px;">
                            <div v-if="optionsIndex!=pIndex" style="width: 21px;height: 21px;border: 1px solid #BFBFBF;border-radius:50%"></div>
                            <img style="width:22px; vertical-align:middle;" v-if="optionsIndex==pIndex" :src="require('@/static/image/icons/tj-selected.png')">
                        </div>
                    </div>
                    <div v-if="pIndex<3" class="sort_list_text_line"></div>

                </div>

            </div>
        </div>

    </div>
</template>
<script>
    import SearchV2 from "../../template/community/search/searchV2";
    import DateRangerV1 from "../../template/community/date/dateRangerV1";
    export default {
        name: 'statistics-details',
        components: {SearchV2,DateRangerV1},

        data(){
            return{
                date:[], //搜索时间
                authList:[], //权限列表
                storeList:[], //商品列表
                pointList:[], //自提点列表
                show:false,//操作的板块
                showTip:'', //操作的板块
                storeIndex:-1,
                keyword:'', //商品
                keywordTwo:'',// 供货商
                keywordThree:'',// 自提点名称
                options: [
                    { name: "商品数升序", id: "countAsc" },
                    { name: "商品数降序", id: "countDesc" },
                    { name: "利润升序", id: "priftAsc" },
                    { name: "利润降序", id: "priftDesc" },

                ],
                optionsIndex:1,
                descVal:'countDesc',
                orderShow:false,
                coverShow:false,
            }
        },

        created () {
            this.getYearDate()
            //获取统计数据

            this.date.toString();
            this.platformProfitByGoods({date:this.date})
            //获取权限列表
            this.myCenterInfo()
        },

        methods: {
            getYearDate:function(){

                var today = new Date();
                var year = today.getFullYear();
                var month = today.getMonth()+1;
                var day = today.getDate();

                var laskTime=today.getTime()-1*24*60*60*1000;
                var tragetTime=new Date(laskTime);
                var tragetYear = tragetTime.getFullYear();
                var tragetMonth = tragetTime.getMonth()+1;
                var tragetDay = tragetTime.getDate();
                this.date=tragetYear+'-'+tragetMonth+'-'+tragetDay+','+year+'-'+month+'-'+day

            },
            //时间搜索
            chooseDate(date) {
                this.date=date.toString();

                this.platformProfitByGoods()
            },

            //点击排序
            checkOrder(index,descVal){
                this.optionsIndex=index
                this.descVal=descVal
                this.orderShow=false
                this.platformProfitByGoods()
            },

            search () {
                this.platformProfitByGoods()

            },

            //获excel报表
            async platformProfitByGoods() {
                this.toast = this.$toast.loading("数据加载中...");
                try {
                    let params= {
                        store_keyword:this.keywordTwo,
                        point_keyword:this.keywordThree,
                        date:this.date,
                        goods_keyword:this.keyword,
                        desc:this.descVal
                    }
                    let query = await this.$api.community.points.platformProfitByGoods(params)

                    this.pointList = query.data.pointArr;
                    this.storeList=query.data.storesList;
                    this.toast.hide()

                } catch (error) {
                    this.toast.hide()
                }

            },


            async myCenterInfo () {

                try {
                    let query = await this.$api.community.points.getStatisticsAuth()
                    let getAuthList=query.data
                    if (getAuthList.length>0){
                        getAuthList.forEach((item, index)=>{
                            this.authList.push(item.auth_id)
                        })
                    }
                    console.log('getStatisticsAuth success', this.authList)
                } catch (error) {
                    console.log('dataInfo error', error)
                }
            },
            showStoresList(index,showTip){

                if (index==this.storeIndex && this.showTip==showTip){
                    this.show=!this.show
                }else{
                    this.storeIndex=index
                    this.show=true
                }
                this.showTip=showTip

            }
        }
    }
</script>

<style scoped lang="scss">
    div{
        border:0px solid red;
    }
    .sort_list{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position:fixed;
        top:0;
        left:0;
        display: flex;
        align-items: center;
        .sort_list_select{
            width: 278px;
            height: 217px;
            background: #FFFFFF;
            border-radius: 8px;
            margin:auto;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #303030;

            .sort_list_text{
                width: 259px;
                height:51px;
                line-height:51px;
                margin:auto;
                display: flex;
                justify-content:space-between;
                align-items: center;
            }
            .sort_list_text_line{
                width: 259px;
                margin:auto;
                height: 1px;
                background: #F2F2F2;
            }

        }
    }
    .self-extract-container {
        background: #fff;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        padding-top: 10px;

        .header{
            margin:auto;
            width: 345px;
            padding-bottom: 15px;
            .header-search{

                height: 36px;
                background: #F6F6F6;
                border-radius: 18px;
            }

        }

        .div-middle{
            height: 8px;
            width:375px;
            background: #F8F8F8;
        }

        .tongji-body{
            margin:auto;
            width: 345px;
            .tongji-head{
                height:57px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #303030;
                line-height: 57px;
                display: flex;
                align-items: center;
                justify-content:space-between;

                .tongji-head-tip{
                    width: 4px;
                    height: 16px;
                    background: linear-gradient(180deg, #FBA332 0%, #F46A17 100%);
                    border-radius: 4px;
                    margin:4px
                }

            }


            .tongji-result{
                width: 345px;
                background: rgba(244, 106, 23, 0.1);
                border-radius: 6px;
                margin-bottom: 9px;

                .tongji-result-title{
                    height: 39px;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #303030;
                    line-height: 39px;
                    padding-left: 13px;
                }
                .tongji-result-line{
                    width: 315px;
                    height: 1px;
                    background: #FFDFCB;
                    margin: auto;
                }
                .tongji-result-desc{
                    padding-left: 13px;
                    height: 37px;
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #F46A17;
                    line-height: 37px;
                    display:flex;
                    justify-content:space-between;
                    align-items: center;

                    .tongji-result-desc-tip{

                        width:10px;
                        line-height:37px;

                        margin-right: 10px;
                    }
                }



                .tongji-result-details{
                    padding-left: 13px;
                    height: 37px;
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 15px;
                    display:flex;
                    justify-content:space-between;
                    align-items: center;

                    .tongji-result-details-tip{
                        width:50px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        margin-right: 10px;
                        text-align: right;


                    }
                }

                .tongji-result-details-money{
                    width:315px;
                    margin:auto;
                    border-top:1px solid rgba(23, 127, 244, 0.1);
                    padding-left: 0px;
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: rgb(23, 127, 244);
                    line-height: 24px;
                }

            }


        }

    }
</style>
